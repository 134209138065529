import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import aircallIcon from "../images/v4/homepage/icons/aircallIcon.svg";
import facebookIcon from "../images/v4/homepage/icons/facebookIcon.png";
import gmailIcon from "../images/v4/homepage/icons/gmailIcon.svg";
import googleCalendarIcon from "../images/v4/homepage/icons/googleCalendarIcon.svg";
import jiraIcon from "../images/v4/homepage/icons/jiraIcon.svg";
import shopifyIcon from "../images/v4/homepage/icons/shopifyIcon.svg";
import krispcallIcon from "../images/v4/homepage/icons/krispcallIcon.svg";
import linkedInIcon from "../images/v4/homepage/icons/linkedInIcon.svg";
import weChatIcon from "../images/v4/homepage/icons/weChatIcon.svg";
import googleDriveIcon from "../images/v4/homepage/icons/googleDriveIcon.png";
import instagramIcon from "../images/v4/homepage/icons/instagramIcon.png";
import mailchimpIcon from "../images/v4/homepage/icons/mailchimpIcon.svg";
import mailerLiteIcon from "../images/v4/homepage/icons/mailerLiteIcon.svg";
import messangerIcon from "../images/v4/homepage/icons/messangerIcon.svg";
import microsoftIcon from "../images/v4/homepage/icons/microsoftIcon.svg";
import oneDriveIcon from "../images/v4/homepage/icons/outlookIcon.png";
import outloookMailIcon from "../images/v4/homepage/icons/outloookMailIcon.png";
import quickBooksIcon from "../images/v4/homepage/icons/quickBooksIcon.png";
import smtpIcon from "../images/v4/homepage/icons/smtpIcon.png";
import telegramIcon from "../images/v4/homepage/icons/telegramIcon.svg";
import whatsappIcon from "../images/v4/homepage/icons/whatsappIcon.svg";
import xeroIcon from "../images/v4/homepage/icons/xeroIcon.svg";
import zoomIcon from "../images/v4/homepage/icons/zoomIcon.png";

import dearInventoryIcon from "../images/v4/homepage/icons/dearInventoryIcon.svg";
import googleContactsLogo from "../images/v4/homepage/icons/googleContactsLogo.svg";
import googleFormsIcon from "../images/v4/homepage/icons/googleFormsIcon.svg";
import googleSheetIcon from "../images/v4/homepage/icons/googleSheetIcon.svg";
import outlookCalendarIcon from "../images/v4/homepage/icons/outlookCalendarIcon.svg";
import outlookContactIcon from "../images/v4/homepage/icons/outlookContactIcon.svg";
import outlookIcon from "../images/v4/homepage/icons/outlookIcon.svg";
import whatsappCloudIcon from "../images/v4/homepage/icons/whatsappCloudIcon.svg";
import whatsappTwilioIcon from "../images/v4/homepage/icons/whatsappTwilioIcon.svg";

import featureImg1 from "../animations/v3/homepage/json/Multichannel.json";
import featureImg2 from "../animations/v3/homepage/json/Workflow.json";
import featureImg3 from "../images/v4/homepage/images/featureImg3.png";
import featureImg4 from "../images/v4/homepage/images/featureImg4.png";
import featureImg5 from "../animations/v3/homepage/json/Analytics.json";
import featureImg6 from "../animations/v3/homepage/json/Security.json";
import thumbnailImage from "../images/v4/homepage/images/thumbnailImage.png";
import benefitImage1 from "../animations/v3/homepage/json/benefitImage1.json";
import benefitImage2 from "../animations/v3/homepage/json/benefitImage2.json";
import benefitImage3 from "../animations/v3/homepage/json/benefitImage3.json";
import benefitImage4 from "../animations/v3/homepage/json/benefitImage4.json";
import benefitImage5 from "../animations/v3/homepage/json/benefitImage5.json";
import benefitImage6 from "../animations/v3/homepage/json/benefitImage6.json";
import benifitListIcon1 from "../images/v4/homepage/icons/benifitListIcon1.svg";
import benifitListIcon2 from "../images/v4/homepage/icons/benifitListIcon2.svg";
import benifitListIcon3 from "../images/v4/homepage/icons/benifitListIcon3.svg";
import benifitListIcon4 from "../images/v4/homepage/icons/benifitListIcon4.svg";
import benifitListIcon5 from "../images/v4/homepage/icons/benifitListIcon5.svg";
import benifitListIcon6 from "../images/v4/homepage/icons/benifitListIcon6.svg";
import assistAiFeatureIcon from "../images/v4/homepage/icons/assistAiFeatureIcon.svg";
import whatsAppFeatureIcon from "../images/v4/homepage/icons/whatsAppFearureIcon.svg";
import getOnPlayStore from "../images/v4/homepage/images/getOnPlayStore.png";
import getOnAppleStore from "../images/v4/homepage/images/getOnAppleStore.png";
import aiSectionVideo from "../animations/v3/homepage/json/aiSectionVideo.json";
import whatsappSection from "../animations/v3/homepage/json/whatsappSection.json";
import downloadSection from "../animations/v3/homepage/downloadSection.webm";
import heroSection from "../animations/v3/homepage/heroSection.mp4";
import VideoComponent from "../components/VideoComponent/VideoComponent";
import { motion } from "framer-motion";
import TestimonialSlider from "../components/v3/TestimonialSlider/TestimonialSlider";
import PlayableVideoComponent from "../components/VideoComponent/PlayableVideoComponent";
import SEO from "../components/SEO";
import Link from "../components/v2/Link";
import LazyImage from "../components/v4/LazyLoadImage/LazyImage";
import LottieIcon from "../components/v4/LottieIcon";

const useStyles = makeStyles((theme) => ({
  // gradientBackground:{
  //   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  // }
  innerHeroSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    margin: "auto",
    textAlign: "center",
  },
  aiSection: {
    background: "#031E31",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& $aiSectionLeft": {
      gap: "16px",
    },
    "& .container": {
      paddingTop: "270px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .container": {
        paddingTop: "120px",
      },
    },
  },
  aiSectionLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  aiSectionRight: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "30px",
    },
  },
  integrationLeft: {
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  whatsappSection: {
    background: "#015352",
    "& $aiSectionLeft": {
      width: "45%",
      gap: "25px",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
    },
  },
  performanceSection: {
    background: "#401960",
    "& $integrationLeft": {
      width: "50%",
    },
    "@media only screen and (max-width: 600px)": {
      "& $integrationLeft": {
        width: "100%",
      },
    },
  },
  statsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "15px",
    },
  },
  statsBox: {
    width: "24%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "50px",
    background: "#310059",
    borderRadius: "30px",
    padding: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
    },
  },
  statsBoxBody: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
    "& .border": {
      border: "1px solid #FFFDFD40",
    },
    "& ul": {
      paddingLeft: "18px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& ul": {
        paddingLeft: "18px",
      },
      "& li": {
        marginBottom: "10px",
      },
    },
  },
  statsFont: {
    fontSize: "80px",
  },
  downloadSection: {
    background: "#FFECE4",
    "& $aiSectionLeft": {
      width: "50%",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
    },
  },
  benfitListContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: "150px",
    "@media only screen and (max-width: 600px)": {
      paddingBottom: "60px",
    },
  },
  benfitListItemOuter: {
    background: "#fff",
    padding: "20px",
    width: "100%",
    transition: "0.5s ease",
    "&:hover": {
      // background: $(--hover-bg-color),
      "& $absoluteImage": {
        opacity: 1,
        visibility: "visible",
      },
    },
    "@media only screen and (max-width: 600px)": {
      "&.active": {
        background: "transparent",
      },
      padding: "10px 15px",
    },

    "&.active": {
      "& $absoluteImage": {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  benfitListItem: {
    display: "flex",
    width: "90%",
    maxWidth: "1200px",
    margin: "0 auto",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "40px",
    position: "relative",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
      alignItems: "flex-start",
    },
  },
  benfitListItemContent: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    gap: "10px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benfitIcon: {
    width: "55px",
    "@media only screen and (max-width: 600px)": {
      width: "40px",
    },
  },
  absoluteImage: {
    position: "absolute",
    right: "0",
    width: "auto",
    height: "auto",
    opacity: 0,
    visibility: "hidden",
    transition: "0.5s ease",
    borderRadius: "32px",
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  benfitSection: {
    "& $aiSectionLeft": {
      width: "43%",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
    },
  },

  aiSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  heroSectionVideoContainer: {
    width: "100%",
    height: "520px",
    marginTop: "100px",
    overflow: "hidden",
    borderRadius: "35px 35px 0 0",
    "@media only screen and (max-width: 600px)": {
      height: "auto",
      marginTop: "60px",
    },
  },
  downloadSectionButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    "& img": {
      width: "180px",
    },
    "@media only screen and (max-width: 600px)": {
      "& a": {
        width: "50%",
        "& img": {
          width: "100%",
        },
      },
    },
  },
  commonPageFooterSection: {
    background:
      "transparent linear-gradient(90deg, #011D32 0%, #00182B 100%) 0% 0% no-repeat padding-box",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justufyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  benefitSection: {
    "& .container": {
      paddingBottom: "0",
    },
  },
}));

const Home = () => {
  const classes = useStyles();

  const statsBoxContent = [
    {
      heading: "Small scale companies",
      number: "200+",
      content: [
        "Tailored for growing teams",
        "Easy to set up and use",
        " Built for diverse industries",
      ],
      color: "#FFDFD2",
    },
    {
      heading: "Deals achieved",
      number: "3.5x",
      content: [
        "Auto lead capture",
        " Automated follow-ups",
        " AI-driven lead scoring",
      ],
      color: "#F1DFFF",
    },
    {
      heading: "Revenue increased",
      number: "25%",
      content: [
        "Optimised sales processes",
        " Upselling opportunities",
        " Data-driven insights",
      ],
      color: "#CEE9FF",
    },
    {
      heading: "Customer rating",
      number: "4.5/5",
      content: [
        "Intuitive interface",
        " 24/7 customer support",
        " Feedback-driven updates",
      ],
      color: "#BEE7E7",
    },
  ];

  const benfitList = [
    {
      icon: benifitListIcon2,
      heading: "Complete CRM",
      content:
        "Seamlessly manage leads, opportunities, accounts, and conversations right from your CRM. Gain a 360-degree view of your sales effortlessly.",
      image: benefitImage1,
      bgColor: "#FFF8EE",
      alt: "All-in-one CRM",
    },
    {
      icon: benifitListIcon1,
      heading: "Omnichannel communication",
      content:
        "Unify all communication channels and engage with your customers across every touchpoint. Boost conversion rates in no time.",
      image: benefitImage2,
      bgColor: "#FFF8F5",
      alt: "Omnichannel CRM",
    },
    {
      icon: benifitListIcon3,
      heading: "Streamlined sales",
      content:
        "Organise your sales pipeline and empower your team to close more deals with the least manual work.",
      image: benefitImage3,
      bgColor: "#F9F5FC",
      alt: "Sales management",
    },
    {
      icon: benifitListIcon4,
      heading: "Activity management",
      content:
        "Track and manage sales activities to boost efficiency. Gain actionable insights to refine your sales processes and improve performance.",
      image: benefitImage4,
      bgColor: "#E9F3FD",
      alt: "Activity management",
    },
    {
      icon: benifitListIcon6,
      heading: "Automated workflows",
      content:
        "Accelerate your sales process with automated follow-ups, reminders, lead nurturing sequences, and more.",
      image: benefitImage5,
      bgColor: "#F1FAF9",
      alt: "Workflow automation",
    },
    {
      icon: benifitListIcon5,
      heading: "AssistAI intelligence",
      content:
        "Leverage AI intelligence to analyse conversations, assess intent, and gain actionable insights.",
      image: benefitImage6,
      bgColor: "#F9F5FC",
      alt: "AI CRM",
    },
  ];

  const allIcons = [
    {
      img: whatsappIcon,
      title: "WhatsApp",
      color: "#25D366",
    },
    {
      img: oneDriveIcon,
      title: "One Drive",
      color: "#0364B8",
    },
    {
      img: instagramIcon,
      title: "Instagram",
      color: "#FF6D1A",
    },
    {
      img: quickBooksIcon,
      title: "QuickBook",
      color: "#1AB3D6",
    },
    {
      img: mailchimpIcon,
      title: "Mailchimp",
      color: "#FFE01B",
    },
    {
      img: linkedInIcon,
      title: "LinkedIn",
      color: "#0866FF",
    },
    {
      img: googleDriveIcon,
      title: "Google Drive",
      color: "#00832D",
    },
    {
      img: telegramIcon,
      title: "Telegram",
      color: "#29A8EA",
    },
    {
      img: microsoftIcon,
      title: "Office 365",
      color: "#FF9D9D",
    },
    {
      img: krispcallIcon,
      title: "KrispCall",
      color: "#DF48A8",
    },
    {
      img: zoomIcon,
      title: "Zoom",
      color: "#1AB3D6",
    },
    {
      img: gmailIcon,
      title: "Gmail",
      color: "#EA4335",
    },
    {
      img: facebookIcon,
      title: "Facebook",
      color: "#0866FF",
    },
    {
      img: jiraIcon,
      title: "Jira",
      color: "#0866FF",
    },
    {
      img: xeroIcon,
      title: "Xero",
      color: "#1AB3D6",
    },
    {
      img: shopifyIcon,
      title: "Shopify",
      color: "#1AB3D6",
    },
    {
      img: googleCalendarIcon,
      title: "Google Calendar",
      color: "#FBBC04",
    },
    {
      img: mailerLiteIcon,
      title: "Mailerlite",
      color: "#00AC47",
    },
    {
      img: messangerIcon,
      title: "Messenger",
      color: "#DF48A8",
    },
    {
      img: aircallIcon,
      title: "Aircall",
      color: "#00BD82",
    },
    {
      img: weChatIcon,
      title: "WeChat",
      color: "#1AB3D6",
    },

    {
      img: dearInventoryIcon,
      title: "Dear Inventory",
      color: "#1AB3D6",
    },
    {
      img: googleContactsLogo,
      title: "Google Contacts",
      color: "#1AB3D6",
    },
    {
      img: googleFormsIcon,
      title: "Google Forms",
      color: "#1AB3D6",
    },
    {
      img: googleSheetIcon,
      title: "Google Sheets",
      color: "#1AB3D6",
    },
    {
      img: outlookCalendarIcon,
      title: "Outlook Calendar",
      color: "#1AB3D6",
    },
    {
      img: outlookContactIcon,
      title: "Outlook Contact",
      color: "#1AB3D6",
    },
    {
      img: outlookIcon,
      title: "Outlook",
      color: "#1AB3D6",
    },
    {
      img: whatsappCloudIcon,
      title: "WhatsApp Cloud",
      color: "#1AB3D6",
    },
    {
      img: whatsappTwilioIcon,
      title: "WhatsApp Twilio",
      color: "#1AB3D6",
    },
  ];

  // Function to distribute icons across three tracks
  function distributeIcons(icons) {
    // Sort icons alphabetically to ensure consistent distribution
    const sortedIcons = [...icons].sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    const logotrack1 = [];
    const logotrack2 = [];
    const logotrack3 = [];

    sortedIcons.forEach((icon, index) => {
      // Distribute icons based on their index
      switch (index % 3) {
        case 0:
          logotrack1.push(icon);
          break;
        case 1:
          logotrack2.push(icon);
          break;
        case 2:
          logotrack3.push(icon);
          break;
      }
    });

    return {
      logotrack1,
      logotrack2,
      logotrack3,
    };
  }

  // Get the distributed icons
  const { logotrack1, logotrack2, logotrack3 } = distributeIcons(allIcons);

  useEffect(() => {
    const wrapper = document.querySelector(".words");
    const words = wrapper.querySelectorAll("span");
    const currentWord = wrapper.querySelector("span.current");
    const wordsWidths = Array.from(words).map((word) => word.offsetWidth);
    const maxWordsWidth = Math.max(...wordsWidths);
    const CURRENT_CLASS = "current";
    const NEXT_CLASS = "next";

    wrapper.style.setProperty("--width", `${currentWord.offsetWidth}px`);
    wrapper.style.setProperty("--width-mobile", `${maxWordsWidth}px`);
    wrapper.style.setProperty(
      "--color",
      "linear-gradient(90deg, #084FFF 0%, #00BFA5 100%)"
    );
    setInterval(() => {
      const currentWord = wrapper.querySelector("span.current");
      const nextWord = wrapper.querySelector("span.next");
      const nextNextWord = nextWord.nextElementSibling
        ? nextWord.nextElementSibling
        : wrapper.firstElementChild;
      currentWord.classList.remove(CURRENT_CLASS);
      nextWord.classList.remove(NEXT_CLASS);
      nextWord.classList.add(CURRENT_CLASS);
      nextNextWord.classList.add(NEXT_CLASS);
      wrapper.style.setProperty(
        "--color",
        "linear-gradient(90deg, #084FFF 0%, #00BFA5 100%)"
      );
      wrapper.style.setProperty("--color", nextWord.dataset.color);
      wrapper.style.setProperty("--color-bg", nextWord.dataset.bgColor);
      wrapper.style.setProperty("--width", `${nextWord.offsetWidth}px`);
    }, 3000);

    class InfiniteScroll {
      constructor(row, speed) {
        this.row = row;
        this.track = row.querySelector(".logos-track");
        this.speed = speed;
        this.position = 0;
        this.direction = row.dataset.direction || "left";
        this.init();
      }

      init() {
        // Clone the track content
        const content = this.track.innerHTML;
        this.track.innerHTML = content + content + content;

        // Calculate total width
        const logos = this.track.querySelectorAll(".logo");
        let totalWidth = 0;
        logos.forEach((logo) => {
          totalWidth +=
            logo.offsetWidth +
            parseInt(getComputedStyle(logo).marginLeft) +
            parseInt(getComputedStyle(logo).marginRight);
        });

        // Set row height
        this.row.style.height = logos[0].offsetHeight + 20 + "px";

        // Set initial position for right-to-left movement
        if (this.direction === "left") {
          this.position = 0;
        } else {
          this.position = -this.track.offsetWidth / 3;
        }

        // Start animation
        this.animate();
      }

      animate() {
        const animate = () => {
          // Update position based on direction
          if (this.direction === "left") {
            this.position -= this.speed;
            // Reset position when one-third of content has scrolled
            const oneThirdWidth = -this.track.offsetWidth / 3;
            if (this.position <= oneThirdWidth) {
              this.position = 0;
            }
          } else {
            this.position += this.speed;
            // Reset position when one-third of content has scrolled
            if (this.position >= 0) {
              this.position = -this.track.offsetWidth / 3;
            }
          }

          this.track.style.transform = `translateX(${this.position}px)`;
          requestAnimationFrame(animate);
        };

        requestAnimationFrame(animate);
      }
    }

    // Initialize all rows with different speeds and directions
    document.querySelectorAll(".logos-row").forEach((row) => {
      const speed = parseFloat(row.dataset.speed) || 1;
      new InfiniteScroll(row, speed);
    });
    // const videoSection = document.querySelectorAll('video')
    // console.log(videoSection,'videoSection');
    // videoSection?.play()
    // document.getElementById("videoSection").play()
  }, []);

  const [activeView, setActveView] = useState(0);

  return (
    <main>
      <SEO
        title={"Pepper Cloud: #1 AI-Powered Sales CRM Software Built for SMEs"}
        pathname={"https://peppercloud.com/"}
        canonical={"https://peppercloud.com/"}
        keywords="Best sales CRM software , sales crm software for small business,Singapore CRM,CRM system,CRM software,sales CRM software,Sales CRM system,Sales CRM Solution"
        description={
          " The best all-in-one sales CRM software with AI. Capture, automate and convert leads with features like sales & marketing automation, powerful CRM integrations, and more"
        }
        ogData={{
          "og:image": [
            "https://peppercloud.com/meta/og/1200X630/home.png", // Primary image
            "https://peppercloud.com/meta/og/1024X512/home.png", // Fallback option (close to ideal size)
            "https://peppercloud.com/meta/og/1X1/home.png", // Optional, avoid using it as primary
          ],
        }}
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
      />
      <section className="gradientBackground">
        <div id="bg-wrap-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid slice"
          >
            <defs>
              <radialGradient
                id="edgeGradient"
                cx="50%"
                cy="50%"
                r="70%"
                fx="50%"
                fy="50%"
              >
                <stop offset="0%" stop-color="rgba(255,0,255,0.7)">
                  <animate
                    attributeName="stop-color"
                    values="rgba(255,0,255,0.7);rgba(255,255,0,0.7);rgba(0,255,255,0.7);rgba(0,255,0,0.7);rgba(255,0,255,0.7)"
                    dur="10s"
                    repeatCount="indefinite"
                  />
                </stop>
                <stop offset="70%" stop-color="rgba(255,255,255,0.3)" />
                <stop offset="100%" stop-color="rgba(255,255,255,0)" />
              </radialGradient>
            </defs>

            {/* <!-- White background --> */}
            <rect width="100%" height="100%" fill="white" />

            {/* <!-- Animated edge gradient --> */}
            <rect width="100%" height="100%" fill="url(#edgeGradient)">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="20s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
        </div>
        <div id="bg-wrap-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid slice"
          >
            <defs>
              <radialGradient
                id="edgeGradient"
                cx="50%"
                cy="50%"
                r="70%"
                fx="50%"
                fy="50%"
              >
                <stop offset="0%" stop-color="rgba(255,0,255,0.7)">
                  <animate
                    attributeName="stop-color"
                    values="rgba(255,0,255,0.7);rgba(255,255,0,0.7);rgba(0,255,255,0.7);rgba(0,255,0,0.7);rgba(255,0,255,0.7)"
                    dur="10s"
                    repeatCount="indefinite"
                  />
                </stop>
                <stop offset="70%" stop-color="rgba(255,255,255,0.3)" />
                <stop offset="100%" stop-color="rgba(255,255,255,0)" />
              </radialGradient>
            </defs>

            {/* <!-- White background --> */}
            <rect width="100%" height="100%" fill="white" />

            {/* <!-- Animated edge gradient --> */}
            <rect width="100%" height="100%" fill="url(#edgeGradient)">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="20s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
        </div>
        <div className="container">
          <div className={classes.innerHeroSection}>
            <motion.h1
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              class="words-wrapper font-wix-semibold color-black1"
            >
              Sell instantly with
              <span class="words">
                <span
                  class="current"
                  data-bg-color="transparent"
                  data-color="linear-gradient(90deg, #084FFF 0%, #00BFA5 100%)"
                >
                  AI-powered
                </span>
                <span
                  class="next"
                  data-bg-color="transparent"
                  data-color="linear-gradient(89deg, #26D366 0%, #00865E 100%)"
                >
                  WhatsApp
                </span>
                <span
                  data-bg-color="transparent"
                  data-color="linear-gradient(270deg, #6544FF 0%, #D64BFF 100%)"
                >
                  Sales
                </span>
              </span>
              <br className="mobVisible" />
              CRM
            </motion.h1>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-20 color-black2"
            >
              Grow your sales with the best CRM system designed for small and
              medium businesses. Connect WhatsApp, Facebook Messenger,
              Instagram, and more and harness the power of artificial
              intelligence to close deals faster!
            </motion.p>
            <Link
              // initial={{ opacity: 1, y: 20 }}
              // whileInView={{ opacity: 1, y: 0 }}
              // transition={{ duration: 0.3 }}
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact"
              // target="_blank"
            >
              Book a free demo
            </Link>
          </div>
          {/* <motion.div
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className={clsx(classes.heroSectionVideoContainer, "mobVisible")}
          >
            <motion.div
              style={{ display: "flex" }}
              initial={{ opacity: 1, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.1 }}
            >
              <VideoComponent source={heroSection} />
            </motion.div>
          </motion.div> */}
          {/* <h1 class="rotate-text"> <span>All-in-one</span> <span>Ai Powered <br/> sales <br/> WhatsApp </span> <span>crm</span></h1> */}
        </div>
      </section>
      <section className={classes.aiSection}>
        <div className="container">
          <motion.div
            className="absoluteVideoContainer"
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <PlayableVideoComponent
              alt="Best sales CRM software"
              thumbnailSrc={thumbnailImage}
              source={heroSection}
            />
          </motion.div>
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <motion.div
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="sectionTag"
              >
                <LazyImage
                  source={assistAiFeatureIcon}
                  alt="AssistAI - AI CRM"
                />
                <p className="font-18 font-white font-wix-semibold">AssistAI</p>
              </motion.div>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-blue5"
              >
                Say hello to <span class="lightBlues">AssistAI</span>
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black7 mt10"
              >
                Leverage the power of human agents and the intelligence of
                AssistAI to boost your sales exponentially. Automate routine
                tasks, enhance sales communications, and optimise sales
                strategies with personalised messaging and powerful insights.
              </motion.p>
              <Link
                to="/sales-crm-features/ai-crm-tool-for-small-business"
                className="transparentButton font-wix-medium font-16 mt10"
              >
                Know more
              </Link>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <LottieIcon
                autoplay
                loop
                animationData={aiSectionVideo}
                hover={false}
                play
              />
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="sectionTag">
            <p className="font-18  font-wix-semibold color-blue1">
              Integrations
            </p>
          </div>
          <div className={classes.integrationHeader}>
            <div className={classes.integrationLeft}>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-black1"
              >
                Completely connected to your business
              </motion.h2>
            </div>
            <div className={classes.integrationRight}>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black2"
              >
                Create a unified powerhouse for your sales operations by
                integrating the best CRM system with the popular tools. Connect
                email inbox, communication channels, accounting software,
                calling software, and more.
              </motion.p>
              <Link
                to="/crm-integrations"
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="transparentLightButton font-wix-medium font-16"
              >
                View integrations
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="logos-container">
          <div class="scroll-container">
            <div class="logos-row" data-speed="0.8" data-direction="left">
              <div class="logos-track">
                {logotrack1.map((logo) => (
                  <div
                    class="logo font-18 font-wix-medium"
                    style={{ background: `${logo.color}20` }}
                  >
                    <img src={logo.img} alt={`${logo.title} integration`} />
                    <p>{logo.title}</p>
                  </div>
                ))}
              </div>
            </div>

            <div class="logos-row" data-speed="0.4" data-direction="right">
              <div class="logos-track">
                {logotrack2.map((logo) => (
                  <div
                    class="logo font-18 font-wix-medium"
                    style={{ background: `${logo.color}20` }}
                  >
                    <img src={logo.img} alt={`${logo.title} integration`} />
                    <p>{logo.title}</p>
                  </div>
                ))}
              </div>
            </div>

            <div class="logos-row" data-speed="0.8" data-direction="left">
              <div class="logos-track">
                {logotrack3.map((logo) => (
                  <div
                    class="logo font-18 font-wix-medium"
                    style={{ background: `${logo.color}20` }}
                  >
                    <img src={logo.img} alt={`${logo.title} integration`} />
                    <p>{logo.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.whatsappSection}>
        <div className="container">
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <motion.div
                className="sectionTag"
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <LazyImage
                  source={whatsAppFeatureIcon}
                  alt="WhatsApp CRM integration"
                />
                <p className="font-18 font-white font-wix-semibold">
                  WhatsApp integration
                </p>
              </motion.div>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-medium font-56 font-white"
              >
                Transform chats into sales deals with WhatsApp
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 font-white"
              >
                Seamlessly automate lead capture, intelligently personalise
                customer communication, automate sales process, and improve
                sales productivity with WhatsApp CRM integration.
              </motion.p>
              <div className="buttonContaienr">
                <Link
                  to={
                    "/whatsapp-crm?utm_source=Homepage&utm_medium=CTA&utm_content=Explore+WhatApp+CRM"
                  }
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="transparentButton font-wix-medium font-16"
                >
                  Explore WhatApp CRM
                </Link>
                <Link
                  to={
                    "/contact?utm_source=Homepage&utm_medium=CTA&utm_content=See+how+it+works"
                  }
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className=" primaryButton font-wix-medium font-16 font-white"
                >
                  See how it works
                </Link>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <LottieIcon
                autoplay
                loop
                animationData={whatsappSection}
                hover={false}
                play
              />
            </motion.div>
          </div>
        </div>
      </section>
      <section className={classes.benefitSection}>
        <div className="container">
          <motion.div
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="sectionTag"
          >
            <p className="font-18  font-wix-semibold color-blue1">Features</p>
          </motion.div>
          <div className={classes.integrationHeader}>
            <div className={classes.integrationLeft}>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-black1"
              >
                Simple, yet powerful all-in-one CRM solution
              </motion.h2>
            </div>
            <div className={classes.integrationRight}>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-18 color-black2"
              >
                Sell faster. Nurture better. Support smarter.
              </motion.p>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black2"
              >
                Revolutionise your entire sales process with the robust features
                of the best CRM software. Empower your team to promote products
                effortlessly, close sales instantly, provide round-the-clock
                customer support, automate the workflows, and experience a
                significant productivity boost.
              </motion.p>
              {/* <motion.a
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="transparentLightButton font-wix-medium font-16"
              >
                View features
              </motion.a> */}
            </div>
          </div>
          <div className="featuresGrid mt60">
            <motion.div
              initial={{ opacity: 1, y: 60 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="featureContainer container1"
            >
              <div className="featureContainerInner">
                <h2 className="font-wix-semibold font-32 color-blue7">
                  Multichannel communication
                </h2>
                <p className="font-wix-medium font-18 color-black2">
                  Deliver the right message on the right channel with the best
                  sales CRM. Effortlessly sync messaging channels, email
                  inboxes, calling software, and more to personalise every
                  interaction, boost engagement, and drive sales through
                  multiple channels.
                </p>
              </div>
              <LottieIcon
                className="lottieAnimation"
                autoplay
                loop
                animationData={featureImg1}
                hover={false}
                play
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 1, y: 60 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="featureContainer container2"
              style={{ backgroundImage: `url(${featureImg4})` }}
            >
              <div className="featureContainerOuter">
                <div className="featureContainerInner">
                  <h2 className="font-wix-semibold font-32 color-blue7">
                    Sales management
                  </h2>
                  <p className="font-wix-medium font-18 color-black2">
                    Build multiple sales pipelines and organise your sales
                    funnel with CRM software. Gain complete visibility into
                    sales activities, drive your team towards winning
                    opportunities and forecast your future revenue.
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 1, y: 60 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="featureContainer container3"
            >
              <div className="featureContainerInner">
                <h2 className="font-wix-semibold font-32 color-blue7">
                  Workflow automation
                </h2>
                <p className="font-wix-medium font-18 color-black2">
                  Boost your sales team's efficiency and eliminate tedious tasks
                  with the top sales CRM platform. Automate repetitive processes
                  like email sequences, task assignments, and more and close
                  deals faster with less effort.
                </p>
              </div>
              <LottieIcon
                className="lottieAnimation"
                autoplay
                loop
                animationData={featureImg2}
                hover={false}
                play
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 1, y: 60 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="featureContainer container4"
            >
              <LottieIcon
                autoplay
                loop
                animationData={featureImg5}
                hover={false}
                play
              />
              <div className="featureContainerInner">
                <h2 className="font-wix-semibold font-32 color-blue7">
                  Analytics and insights
                </h2>
                <p className="font-wix-medium font-18 color-black2">
                  Get an overview of your business performance with an
                  interactive dashboard. Customise it at your convenience and
                  access in-depth insights on your leads, sales, activities,
                  messages, marketing campaigns, and more.
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 1, y: 60 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="featureContainer container5"
              style={{ backgroundImage: `url(${featureImg3})` }}
            >
              <div className="featureContainerOuter">
                <div className="featureContainerInner">
                  <h2 className="font-wix-semibold font-32 color-blue7">
                    Lead capture
                  </h2>
                  <p className="font-wix-medium font-18 color-black2">
                    Capture your leads directly into the sales CRM solution from
                    integrated web forms, incoming messages, or digital ads.
                    Automatically, add them to your sales pipeline and convert
                    them into your customers.
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 1, y: 60 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="featureContainer container6"
            >
              <div className="featureContainerInner">
                <h2 className="font-wix-semibold font-32 font-white">
                  Security
                </h2>
                <p className="font-wix-medium font-18 font-white">
                  Protect your valuable data with a security-first sales CRM
                  tool. The top-tier security features offer rigorous
                  vulnerability testing, data encryption, granular user access
                  management, and two-factor authentication.
                </p>
              </div>
              <LottieIcon
                autoplay
                loop
                animationData={featureImg6}
                hover={false}
                play
              />
            </motion.div>
          </div>
        </div>
      </section>
      <section className={classes.benfitSection}>
        <div className="container">
          <div className={classes.aiSectionLeft}>
            <div className="sectionTag">
              <p className="font-18  font-wix-semibold color-blue1">Benefits</p>
            </div>
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 color-black1"
            >
              Smart CRM tool to simplify your sales team efforts
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black2"
            >
              Experience the combined power of AssistAI intelligence and sales
              expertise to drive unprecedented growth for your business. Chat
              with your prospects, streamline your sales funnel, provide
              real-time updates, and gain an overview of your team’s
              performance.
            </motion.p>
          </div>
        </div>
        <div className={classes.benfitListContainer}>
          {benfitList.map((listItem, index) => (
            <div
              className={clsx(
                classes.benfitListItemOuter,
                "benfitListItemOuter",
                activeView === index ? "active" : ""
              )}
              onMouseOver={() => setActveView(index)}
              style={{
                "--hover-bg-color": listItem.bgColor,
              }}
            >
              <div className={classes.benfitListItem}>
                <LazyImage
                  source={listItem.icon}
                  className={classes.benfitIcon}
                  alt={listItem.alt}
                />
                <div className={classes.benfitListItemContent}>
                  <p className="font-wix-semibold font-20 color-black1">
                    {listItem.heading}
                  </p>
                  <p className="font-wix-regular font-16 color-black2">
                    {listItem.content}
                  </p>
                </div>
                <LottieIcon
                  className={classes.absoluteImage}
                  autoplay
                  loop
                  alt={listItem.alt}
                  animationData={listItem.image}
                  hover={false}
                  play
                />
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className={classes.performanceSection}>
        <div className="container">
          <div className="sectionTag">
            <p className="font-18  font-wix-semibold font-white">Performance</p>
          </div>
          <div className={classes.integrationHeader}>
            <div className={classes.integrationLeft}>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-medium font-56 color-black7"
              >
                Unlock success with the best AI-powered sales CRM
              </motion.h2>
            </div>
            <div className={classes.integrationRight}>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-18 font-white"
              >
                Leverage AssistAI | Increase revenues | Manage expectations
              </motion.p>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-purple3"
              >
                Supercharge your sales team with best-in-class CRM solution,
                leveraging automation and data-driven insights. Eliminate manual
                tasks, strengthen customer relationships, and drive revenue
                growth.
              </motion.p>
              {/* <motion.a
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="transparentButton  font-wix-medium font-16"
              >
                More about Assist Ai
              </motion.a> */}
            </div>
          </div>
          <div className={classes.statsContainer}>
            {statsBoxContent.map((stat, index) => (
              <motion.div
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 1, delay: index * 0.1 }}
                className={classes.statsBox}
              >
                <div className={classes.statsBoxHeader}>
                  <p className="font-18 color-purple3 font-wix-regular">
                    {stat.heading}
                  </p>
                </div>
                <div className={classes.statsBoxBody}>
                  <p
                    style={{ color: `${stat.color}` }}
                    className={clsx(classes.statsFont, "font-wix-medium")}
                  >
                    {stat.number}
                  </p>
                  <div className="border" />
                  <ul>
                    {stat.content.map((data) => (
                      <li className="font-18 color-purple3 font-wix-regular">
                        {data}
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      <section className={classes.downloadSection}>
        <div className="container">
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <div className="sectionTag">
                <p className="font-18  font-wix-semibold color-blue1">
                  Download app
                </p>
              </div>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-black1"
              >
                Close deals anytime, anywhere with <br className="mobHide" />{" "}
                mobile CRM
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black2"
              >
                Equip your sales team to close deals on the go with the Pepper
                Cloud mobile app. Converse with leads and customers across
                various messaging apps, receive custom notifications, access
                comprehensive client profiles, and automate chat interactions.
              </motion.p>
              <motion.div
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={classes.downloadSectionButtonContainer}
              >
                <a
                  href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
                  target="_blank"
                >
                  <LazyImage
                    source={getOnAppleStore}
                    alt="Pepper Cloud Mobile CRM - iOS App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.peppercloud"
                  target="_blank"
                >
                  <LazyImage
                    source={getOnPlayStore}
                    alt="Pepper Cloud Mobile CRM - Google Play"
                  />
                </a>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <VideoComponent source={downloadSection} />
            </motion.div>
          </div>
        </div>
      </section>
      <TestimonialSlider />
      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Join our happy customers and increase your sales revenue
            </motion.h2>
            <Link
              // initial={{ opacity: 1, y: 20 }}
              // whileInView={{ opacity: 1, y: 0 }}
              // transition={{ duration: 0.3 }}
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact"
              // target="_blank"
            >
              Book a free demo
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
